.Topbar {}
#flash{
    width: calc(100% - 100px);
     padding-left: 90px;
}

#trending{
    width: 100px;
}
.sitetitle{
font-size: 35px;
}

.margintops{
    padding-top: 5px;
    padding-left: 10px;
}
