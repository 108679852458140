.Colleges {}

.google-map {
    padding-bottom: 50%;
    position: relative;
}

.google-map iframe {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}
.pt{
    padding-left: 1em;
}
.st{
    padding-left: 1.5em;
}
