.CategorySlider2 {}
.imgwhc{
    object-fit: cover;
}
.heighteighty{
    height: 80px;
}
.heightthreehundred{
    height: 300px;
}
.fontsizethirteen{
    font-size: 13px;
}