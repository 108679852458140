.Admissions {}
.imgwhc{
    width: 100px; 
    height: 100px; 
    object-fit: cover;
}

.fthirteen{
    font-size: 13px;
}
.ffourteen{
    font-size: 13px;
}
.sstyle{
    padding:2px;
}
.rresult{
    margin-top: 20px;
    width: 100%;
}
.rtable{
    width: 100%;
    table-layout: fixed;
}

.rtd{
    width: 100%;
}
.mtop{
    margin-top: 50px;
}

.acrow{
    padding-left: 15px;
    text-align: left;
}

.psubmit{
    padding-top: 15px;
}