.Dashboard {}
.aright{
    width:100%;
    text-align: right;
    font-weight: bold;
    color:red;
    font-size: large;
    cursor: pointer;
}
.wid{
    width:100%;
    max-width: 100%;
}
.txarea{
    margin-bottom: 10px;
}

.ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
  .mwd{
    max-width:100%
  }