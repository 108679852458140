.MainSlider {}

.imgwhc{
    object-fit: cover;
}
.heighteighty{
    height: 80px;
}
.heightfourthirtyfive{
    height: 435px;
}
.cat{
    padding-left: 30px;
}
.rresults{
    padding-top: 5px;
}
.rresults{
    min-height:100%;
    padding: 5px;
}
.rresultser{
    padding: 5px;
}
.mmargin{
    margin-bottom: 20px;
}