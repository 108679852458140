.FeaturedSlider {}

.imgwhc{
    object-fit: cover;
}
.heighteighty{
    height: 80px;
}
.heightthreehundred{
    height: 300px;
}
.fontsizethirteen{
    font-size: 13px;
}
.intdisp{
    display: inline-block;
}
.hstyle{
    padding:10px;
}