.Contact {}

.test{
    width: 100%;
     max-width: 300px;
  }
  
  .ttt{
    width: calc(100% - 100px);
     padding-left: 90px;
  }
  
  