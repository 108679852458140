.Login {}
.wid{
    max-width: 300px;
}
.div2 {
    float: left;
    width: 100%;
}

.div1 {
    float: left;
    width: 50%;
    display: inline-block;
}



@media (max-width: 600px) {
    .div1 {
        width: 100%;
    }
}