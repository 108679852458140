.Sidebar {}
.headerfourteen{
    font-size: 14px;
}
.headerthirteen{
    font-size: 13px;
}
.imgcover{
    object-fit: cover;
}

.imgwhcover{
    width: 100px; 
    height: 100px; 
    object-fit: cover;
}

.hhundred{
    height: 100px; 
}

.hundredper{
    width: 100%;
}